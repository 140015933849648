import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Layout from '../../../components/layout'
import Nav from '../../../components/nav'
import SEO from '../../../components/seo'
import '../../../styles/index.scss'
import OptBtn from '../../../components/button'
import bannerImg from '../../../images/integrations/optiwe_gvamax.jpg'

const IndexPage = () => {
    return (
        <Layout lang="es">
            <SEO
                title="Optiwe - Integración con GVAMax"
                description="Integra Optiwe con GVAMax para centralizar tu base de clientes y
                 manejar eficientemente tus clientes potenciales."
                lang="es"
                locales={[
                    {
                        hreflang: 'es',
                        href: 'https://optiwe.com/es/integraciones/gvamax/'
                    }
                ]}
            />

            <main className="container-fluid ow-banner">
                {/*
                ^*********
                * HEADER *
                **********
                */}
                <Nav
                    lang="es"
                    showButtons
                />

                {/*
                ***************
                * MAIN BANNER *
                ***************
                * TODO: If the banner is used in another view, create a <Banner /> component
                */}
                <div className="container ow-banner__content">
                    <div className="row">
                        <div className="col-xl-6 col-xxl-6 ow-banner__home-content">
                            <h1 className="ow-banner__title">
                                Integra Optiwe con <strong>GVAMax</strong>
                            </h1>
                            <p className="ow-banner__text">
                                La integración de Optiwe con GVAMax ofrece una serie de ventajas
                                para mejorar la gestión de tus inmuebles y la atención al cliente.
                                Al conectar los canales digitales como Whatsapp, Instagram y
                                Facebook con GVAMax, puedes centralizar la atención de tus clientes
                                y potenciales clientes en una sola plataforma. Esto significa que
                                podrás responder rápidamente a todas las consultas y solicitudes de
                                información en tiempo real, lo que aumentará la satisfacción del
                                cliente y la posibilidad de concretar ventas.
                            </p>
                            <p className="ow-banner__text">
                                Además, con la integración de Optiwe, podrás automatizar muchas de
                                las tareas de atención al cliente y gestión de inmuebles, lo que te
                                permitirá ahorrar tiempo y recursos. Por ejemplo, podrás utilizar
                                chatbots para responder preguntas frecuentes y enviar información
                                de tus inmuebles automáticamente a través de Whatsapp, Instagram y
                                Facebook. También podrás recibir notificaciones de nuevos mensajes
                                y consultas en tiempo real, lo que te permitirá responder de manera
                                rápida y efectiva.
                            </p>
                            <p className="ow-banner__text">
                                En resumen, la integración de Optiwe con GVAMax te permitirá
                                mejorar la atención al cliente, ahorrar tiempo y recursos, y
                                aumentar las posibilidades de concretar ventas. Si estás buscando
                                una solución eficaz para gestionar tus inmuebles y mejorar
                                la experiencia del cliente, la integración de Optiwe con GVAMax
                                es la opción ideal.
                            </p>
                            <div className="row">
                                <div className="col-xl-4 mb-3 mb-md-3">
                                    <OptBtn isLink rounded small href="/es/crear-cuenta/">
                                        Regístrate, es grátis!
                                    </OptBtn>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xxl-7 ow-banner__home-image">
                            <img src={bannerImg} alt="optiwe" className="ow-banner__img" />
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    )
}

export default IndexPage
